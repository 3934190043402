
import {
	IonPage,
	IonContent,
	IonIcon,
	IonCheckbox,
	IonPopover,
	IonSelect,
	IonSelectOption,
	IonInput,
	IonList,
	IonItem,
	modalController,
} from "@ionic/vue";
import { helpCircleOutline } from "ionicons/icons";
import { defineComponent, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useRoute, useRouter } from "vue-router";
import settingsApp from "@/libs//settings";
import httpApp from "@/libs/http";
import cartApp from "@/libs/cart";
import { useStore } from "@/store";
import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import UploadNationalIdModal from "@/components/UploadNationalIdModal.vue";

export default defineComponent({
	name: "Order",
	setup() {
		const { t, te } = useI18n();

		const {
			createLoading,
			openToast,
			formatNumberLocale,
		} = useGlobalFunctions();
		const route = useRoute();
		const router = useRouter();
		const storeX = useStore();
		const orderNo = ref(null);
		const currentOrder = ref(null);
		const currentOrderItems = ref([]);
		const returnable = ref(false);
		const couldCanceled = ref(false);

		// const openReturnOrder = () => {
		//   const url =
		//     "/" + storeX.state.storeCode + "/account/return-order/" + orderNo.value;
		//   router.push(url);
		// };

		const isOrderReturnable = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("checkReturnableOrder"), {
					order_id: currentOrder.value.entity_id,
				})
				.then(async (response: any) => {
					if (response.success) {
						returnable.value =
							response.is_returnable && currentOrder.value.status == "complete"
								? true
								: false;
					}
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		const setItemSellerData = (item) => {
			httpApp
				.sendPostRequest(
					settingsApp.getEndpointUrl("getSellerOrderDataBySalesItem"),
					{
						order_id: currentOrder.value.entity_id,
						item_id: item.item_id,
					}
				)
				.then(async (response: any) => {
					if (response.success) {
						item["order_status"] = response.data.order.status_text;
					} else {
						item["order_status"] = currentOrder.value.status_label;
					}
				})
				.catch((error) => {
					console.log(error, "error");
					item["order_status"] = currentOrder.value.status_label;
				});
		};

		const getOrder = () => {
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("getOrder"), {
					order_no: orderNo.value,
					store_view: storeX.state.storeCode,
				})
				.then(async (response: any) => {
					if (!Object.prototype.hasOwnProperty.call(response, "error")) {
						currentOrder.value = response.order;
						currentOrderItems.value = response.items;
						currentOrderItems.value.forEach((item) => {
							setItemSellerData(item);
						});
						isOrderReturnable();
						couldCanceled.value =
							currentOrder.value.can_cancel &&
							currentOrder.value.status == "pending";
					}
				})
				.catch((error) => {
					console.log(error, "error");
				});
		};

		const cancelOrder = async () => {
			const loading = await createLoading(t("pleaseWait"));
			loading.present();
			httpApp
				.sendPostRequest(settingsApp.getEndpointUrl("cancelOrder"), {
					order_no: orderNo.value,
				})
				.then(async (resp: any) => {
					loading.dismiss();
					if (!Object.prototype.hasOwnProperty.call(resp, "error")) {
						getOrder();
					} else {
						if (te("apiMessages.codes." + resp.error.code)) {
							openToast(t("apiMessages.codes." + resp.error.code), "danger");
						} else {
							openToast(resp.error.message, "danger");
						}
						if (resp.error.code == "core_customers_132") {
							router.replace("/account");
						}
					}
				})
				.catch((error) => {
					loading.dismiss();
					console.log(error, "error");
				});
		};

		const formateDate = (date: any) => {
			const dateArr = date.split(" ");
			return dateArr[0];
		};

		const shippingAddress = (order) => {
			return (
				order.shipping_address.address_label +
				" :" +
				order.shipping_address.street +
				", " +
				order.shipping_address.city +
				"," +
				order.shipping_address.region
			);
		};

		const formateQty = (qty) => {
			const finallqty = qty * 1;
			return finallqty;
		};

		const netValue = () => {
			const netval =
				Number(currentOrder.value.total_invoiced) -
				Number(currentOrder.value.total_refunded);
			return formatNumberLocale(netval) + " " + storeX.state.currency;
		};

		function ionViewWillEnter() {
			currentOrder.value = null;
			orderNo.value = route.params.orderNo;
			getOrder();
		}

		return {
			t,
			storeX,
			ionViewWillEnter,
			currentOrder,
			currentOrderItems,
			orderNo,
			formateDate,
			shippingAddress,
			formateQty,
			returnable,
			couldCanceled,
			//openReturnOrder,
			cancelOrder,
			netValue,
		};
	},
	components: {
		IonPage,
		IonContent,
		AccountNavComponent,
		MainHeader,
		MainFooter,
	},
});
